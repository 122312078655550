<template>
	<v-container fluid>
		<v-row>
			<v-col>
				<div class="text-right">
					<v-menu offset-y>
						<template v-slot:activator="{ on }">
							<Button
								v-on="on"
								class="ms-4"
								v-text="filter_timerange.selected.title"
							>
							</Button>
						</template>
						<v-list>
							<v-list-item
									v-for="(item, index) in filter_timerange.items"
									:key="index"
									@click="change_timerange(item)"
							>
								<v-list-item-title>{{ item.title }}</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
				</div>
			</v-col>
		</v-row>

		<v-row>
			<v-col
					v-for="(chart, index) in charts"
					:key="index"
					cols="12"
			>
				<v-card>
					<v-card-text>
						<highcharts :options="chart" :updateArgs="[true, true]"></highcharts>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import Vue from 'vue'
	import { globalMixIn } from '@/App.vue'

	import HighchartsVue from 'highcharts-vue'
	import Highcharts from 'highcharts'
	import stockInit from 'highcharts/modules/stock'

	stockInit(Highcharts)

	Vue.use(HighchartsVue)

	const defaultSettings = {
		credits: {
			enabled: false,
		},
		tooltip: {
			crosshairs: true,
			shared: true,
		},
		xAxis: {
			type: 'datetime',
		},
		plotOptions: {
			series: {
				marker: {
					enabled: false,
				},
			},
		},
		subtitle: {
			text: 'In avarage'
		},
		yAxis: {
			title: {
				enabled: false,
			},
		},
		series: [],
	}

	export default {
		data () {
			return {
				charts: {
					socialMedia: {
						...defaultSettings,
						title: {
							text: 'Social Media followers'
						},
						subtitle: {
							text: ''
						},
					},
				},
				flags: [],
				filter_timerange: {
					selected: { title: 'Last quarter', days: '90' },
					items: [
						{ title: 'All history', days: '' },
						{ title: 'Last year', days: '365' },
						{ title: 'Last quarter', days: '90' },
						{ title: 'Last month', days: '30' },
						{ title: 'Last week', days: '7' },
						{ title: 'Last day', days: '1' },
					],
				},
			}
		},
		methods: {
			change_timerange (e) {
				this.filter_timerange.selected = e
				this.refetch_all_data()
			},
			getFlags() {
				this.getApi(`${process.env.VUE_APP_API_URL}/api/statistics/chart-flags?timerange=${this.filter_timerange.selected.days}`)
					.then(res => this.flags = res.data)
			},
			getStatistics (endpoint, chart) {
				this.getApi(`${process.env.VUE_APP_API_URL}/api/statistics/${endpoint}?timerange=${this.filter_timerange.selected.days}`)
					.then(res => chart.series = [...res.data, ...this.flags])
			},
			refetch_all_data () {
				this.getFlags();
				this.getStatistics('social-media', this.charts.socialMedia);
			}
		},
		mixins: [globalMixIn],
		mounted() {
			this.refetch_all_data()
		},
		name: `StatisticsSocialMedia`
	}

</script>
